<template>
	<div class="rank-position">
		<img v-if="position === 1" src="@/assets/images/leaderboard/medal-1.webp" class="align-with-avatar" />
		<img v-else-if="position === 2" src="@/assets/images/leaderboard/medal-2.webp" class="align-with-avatar" />
		<img v-else-if="position === 3" src="@/assets/images/leaderboard/medal-3.webp" class="align-with-avatar" />
		<div
			v-else
			class="text-center"
			:class="{
				'text-green': position > 3 && position <= 20,
				'text-orange': position > 20,
			}"
		>
			{{ position }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'RankPosition',
	props: { position: Number },
};
</script>

<style lang="scss" scoped>
.rank-position {
	width: 50px;
	font-size: 20px;
	font-weight: bold;

	.text-green {
		color: #58a700;
	}

	.text-orange {
		color: #ea932d;
	}

	.align-with-avatar {
		width: 100%;
	}
}
</style>
