<template>
	<div class="leaderboard-skelton-container">
		<Loader></Loader>
		<v-row v-for="n in 5" :key="n" class="leaderboard-row-skeleton" :style="`opacity: ${1 - 0.1 * n}`">
			<v-col cols="12" class="column-2">
				<div class="w-100 d-flex justify-space-between align-center">
					<div class="d-flex align-center">
						<div class="circle"></div>
						<div class="avatar mx-8">
							<v-skeleton-loader light type="avatar"></v-skeleton-loader>
						</div>
						<v-skeleton-loader light :width="n % 2 === 0 ? 100 + n * 10 : 100 - n * 10" type="text"></v-skeleton-loader>
					</div>
					<v-skeleton-loader light width="60" type="text"></v-skeleton-loader>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Loader from '../other/Loader';

export default {
	name: 'LeaderBoardRowSkeleton',
	components: { Loader },
};
</script>

<style lang="scss" scoped>
.leaderboard-skelton-container {
	position: relative;
}
.leaderboard-row-skeleton {
	.circle {
		background-color: #e5e5e5;
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}
	.avatar {
		width: 50px;
	}
}
</style>
