var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:{ 'sticky-my-row': _vm.boilerplate === false },attrs:{"id":"MyPositionRow"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"my-position-row w-100 py-3 pr-2 d-flex justify-space-between align-center",class:{
				no_rank: _vm.boilerplate,
				'ranked-out-of-20': _vm.boilerplate === false && _vm.position > 20,
				'ranked-in-20': _vm.boilerplate === false && _vm.position <= 20,
			}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.boilerplate)?_c('div',{staticClass:"dash"}):_c('RankPosition',{attrs:{"position":_vm.position}}),_c('div',{class:{ 'ml-4 mr-8': _vm.boilerplate === false, 'mx-8': _vm.boilerplate }},[_c('MyAvatar',{attrs:{"name":_vm.name,"boilerplate":_vm.boilerplate,"position":_vm.position}})],1),_c('div',{staticClass:"name",class:{ 'ortho-text-gray': _vm.boilerplate, 'ortho-icon-black': !_vm.boilerplate }},[_c('b',[_vm._v(_vm._s(_vm.name)+".")])])],1),_c('div',{class:{
					'ortho-text-gray': _vm.boilerplate,
					'ortho-text-green-flash': _vm.boilerplate === false && _vm.position <= 20,
					'ortho-icon-white': _vm.boilerplate === false && _vm.position > 20,
				}},[_c('span',{staticClass:"score"},[_c('b',[_vm._v(_vm._s(_vm.score))])]),(_vm.boilerplate === true)?_c('img',{staticClass:"lightning-icon",attrs:{"src":require("@/assets/icons/lightening-grey.svg")}}):(_vm.boilerplate === false && _vm.position <= 20)?_c('img',{staticClass:"lightning-icon",attrs:{"src":require("@/assets/icons/lightening-green.svg")}}):_c('img',{staticClass:"lightning-icon",attrs:{"src":require("@/assets/icons/lightening-white.svg")}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }