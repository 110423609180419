<template>
	<v-row>
		<v-col cols="12">
			<div class="other-user-row w-100 py-2 pr-2 d-flex justify-space-between align-center">
				<div class="d-flex align-center">
					<RankPosition :position="position" />
					<div class="ml-4 mr-8">
						<OtherUserAvatar :name="name" />
					</div>
					<div class="name ortho-icon-black">
						<b>{{ name }}.</b>
					</div>
				</div>
				<div>
					<span class="score ortho-text-green-flash">
						<b>{{ score }}</b>
					</span>
					<img class="lightIconGrey" src="@/assets/icons/lightening-green.svg" />
				</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import OtherUserAvatar from './OtherUserAvatar';
import RankPosition from './RankPosition';

export default {
	name: 'OtherUserRow',
	props: { name: String, score: Number, position: Number },
	components: { OtherUserAvatar, RankPosition },
};
</script>

<style lang="scss" scoped>
.other-user-row {
	border-radius: 8px;
	* {
		font-family: 'Lato';
	}

	.name,
	.score {
		font-size: 17px;
	}
	.lightIconGrey {
		margin-left: 4px;
		height: 15px;
		transform: translateY(1px);
	}
}
</style>
