<template>
	<div class="unqualified-placeholder">
		<v-container fluid>
			<BoilerplateRow
				:imgSrc="require(`@/assets/images/leaderboard/a1.webp`)"
				:nameWidth="$vuetify.breakpoint.mdAndUp ? 100 : 90"
				:opacity="1"
			/>
			<BoilerplateRow
				:imgSrc="require(`@/assets/images/leaderboard/a2.webp`)"
				:nameWidth="$vuetify.breakpoint.mdAndUp ? 120 : 70"
				:opacity="0.9"
			/>
			<BoilerplateRow
				v-if="$vuetify.breakpoint.mdAndUp"
				:imgSrc="require(`@/assets/images/leaderboard/a3.webp`)"
				:nameWidth="50"
				:opacity="0.8"
			/>
			<BoilerplateRow
				v-if="$vuetify.breakpoint.mdAndUp"
				:imgSrc="require(`@/assets/images/leaderboard/a4.webp`)"
				:nameWidth="140"
				:opacity="0.7"
			/>
			<BoilerplateRow :imgSrc="require(`@/assets/images/leaderboard/a5.webp`)" :nameWidth="100" :opacity="0.6" />
			<BoilerplateRow
				v-if="!passedFirstCondition"
				:imgSrc="require(`@/assets/images/leaderboard/a6.webp`)"
				:nameWidth="140"
				:opacity="0.5"
			/>
			<MyPositionRow
				v-else
				:name="`${profileInfos.first_name} ${profileInfos.last_name ? profileInfos.last_name[0] : ''}`"
				:score="0"
				:class="{ 'my-10': $vuetify.breakpoint.mdAndUp }"
			/>
		</v-container>
	</div>
</template>

<script>
import BoilerplateRow from './BoilerplateRow';
import MyPositionRow from './MyPositionRow';

export default {
	name: 'UnqualifiedPlaceholder',
	components: { BoilerplateRow, MyPositionRow },
	props: {
		profileInfos: Object,
		passedFirstCondition: Boolean,
	},
};
</script>

<style lang="scss" scoped></style>
