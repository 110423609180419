<template>
	<v-row class="boilerplate-row" :style="`opacity: ${opacity}`">
		<v-col cols="12" class="column-2">
			<div class="w-100 d-flex justify-space-between align-center">
				<div class="d-flex align-center">
					<div class="circle"></div>
					<div class="avatar mx-8">
						<img class="avatar-img" :src="imgSrc" />
					</div>
					<v-skeleton-loader light boilerplate :width="nameWidth" type="text"></v-skeleton-loader>
				</div>
				<v-skeleton-loader
					light
					boilerplate
					:width="$vuetify.breakpoint.mdAndUp ? 60 : 30"
					type="text"
				></v-skeleton-loader>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'BoilerplateRow',
	props: {
		imgSrc: String,
		nameWidth: Number,
		opacity: Number,
	},
};
</script>

<style lang="scss" scoped>
.boilerplate-row {
	padding-left: 8px;
	padding-right: 8px;
	.circle {
		background-color: #e5e5e5;
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}
	.avatar {
		width: 50px;
	}
	.avatar-img {
		width: 100%;
	}
}
</style>
