export const capitalize = (str) => {
	if (str.length === 0) return str;
	const texts = str.split(' ');

	const capitalizedText = texts.map((text) => {
		const loweredCaseText = text.toLowerCase();
		return loweredCaseText.charAt(0).toUpperCase() + loweredCaseText.slice(1);
	});
	return capitalizedText.join(' ');
};

export const countWord = (paragraph = '') => {
	const validRegex = /^(?=.*[A-Za-z0-9]).+$/;

	const wordsArray = paragraph
		.trim()
		.replace(/\n/g, ' ')
		.split(/\s+/)
		.filter((word) => word.length > 0 && validRegex.test(word));
	return wordsArray.length;
};

export const findKeywords = (paragraph, keywords) => {
	const lowerCaseParagraph = paragraph.toLowerCase();
	const foundKeywords = keywords.filter((keyword) => {
		return lowerCaseParagraph.includes(keyword.toLowerCase().trim());
	});
	return foundKeywords;
};

/**
 *
 * @param {string} template
 * @param {object} data
 * @returns {string} replace variables in template with data
 */
export const parseTemplate = (template, data) => {
	return template.replace(/\${(.*?)}/g, (match, key) => {
		return data[key.trim()];
	});
};
