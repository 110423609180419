<template>
	<v-row id="MyPositionRow" :class="{ 'sticky-my-row': boilerplate === false }">
		<v-col cols="12">
			<div
				class="my-position-row w-100 py-3 pr-2 d-flex justify-space-between align-center"
				:class="{
					no_rank: boilerplate,
					'ranked-out-of-20': boilerplate === false && position > 20,
					'ranked-in-20': boilerplate === false && position <= 20,
				}"
			>
				<div class="d-flex align-center">
					<div v-if="boilerplate" class="dash"></div>
					<RankPosition v-else :position="position" />
					<div :class="{ 'ml-4 mr-8': boilerplate === false, 'mx-8': boilerplate }">
						<MyAvatar :name="name" :boilerplate="boilerplate" :position="position" />
					</div>
					<div class="name" :class="{ 'ortho-text-gray': boilerplate, 'ortho-icon-black': !boilerplate }">
						<b>{{ name }}.</b>
					</div>
				</div>
				<div
					:class="{
						'ortho-text-gray': boilerplate,
						'ortho-text-green-flash': boilerplate === false && position <= 20,
						'ortho-icon-white': boilerplate === false && position > 20,
					}"
				>
					<span class="score">
						<b>{{ score }}</b>
					</span>
					<img v-if="boilerplate === true" class="lightning-icon" src="@/assets/icons/lightening-grey.svg" />
					<img
						v-else-if="boilerplate === false && position <= 20"
						class="lightning-icon"
						src="@/assets/icons/lightening-green.svg"
					/>
					<img v-else class="lightning-icon" src="@/assets/icons/lightening-white.svg" />
				</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import MyAvatar from './MyAvatar';
import RankPosition from './RankPosition';

export default {
	name: 'MyPositionRow',
	props: { name: String, score: Number, position: Number },
	components: { MyAvatar, RankPosition },
	computed: {
		boilerplate() {
			return this.position == null;
		},
	},
};
</script>

<style lang="scss" scoped>
#MyPositionRow {
	z-index: 2;
}

.my-position-row {
	border-radius: 8px;
	* {
		font-family: 'Lato';
	}
	.name,
	.score {
		font-size: 17px;
	}
	.dash {
		width: 15px;
		height: 3px;
		background-color: #e5e5e5;
	}
	.lightning-icon {
		margin-left: 4px;
		height: 15px;
		transform: translateY(1px);
	}
}

.my-position-row.no_rank {
	background-color: #f7f7f7;
	padding-left: 8px;
	padding-right: 8px;
}

.my-position-row.ranked-out-of-20 {
	background-color: #ffb2b2;
}

.sticky-my-row {
	position: sticky;
	bottom: 0px;
	top: -12px;
	@media screen and (max-width: 959px) {
		bottom: -12px;
	}
}

.my-position-row.ranked-in-20 {
	border: 3px solid #2dc7ad;
	background-color: white;
}
</style>
