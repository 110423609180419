<template>
	<div class="other-user-avatar-container">
		<v-badge v-if="fiftyFifty" bottom color="green" dot offset-x="10" offset-y="10">
			<v-avatar :color="avatarBgColor" size="49">
				<span class="other-user-avatar-name">{{ name[0] }}</span>
			</v-avatar>
		</v-badge>
		<v-avatar v-else :color="avatarBgColor" size="49">
			<span class="other-user-avatar-name">{{ name[0] }}</span>
		</v-avatar>
	</div>
</template>

<script>
import ColorCodeByLetterMap from '@/constants/colorCodeForLetters.js';
export default {
	name: 'OtherUserAvatar',
	props: { name: String },
	computed: {
		fiftyFifty() {
			return Math.random() < 0.5;
		},
		avatarBgColor() {
			return ColorCodeByLetterMap.get(this.name[0].toLowerCase());
		},
	},
};
</script>

<style lang="scss" scoped>
.other-user-avatar-container {
	.other-user-avatar-name {
		font-family: 'Lato';
		font-size: 25px;
		font-weight: 600;
		color: white;
	}
}
</style>
