<template>
	<div
		class="my-avatar-container"
		:class="{
			boilerplate: boilerplate,
			'rank-not-in-toplist': boilerplate === false && position > 20,
			'rank-in-toplist': boilerplate === false && position <= 20,
		}"
	>
		<v-badge class="my-avatar-badge" bottom color="green" dot offset-x="10" offset-y="10">
			<v-avatar class="my-avatar" size="49">
				<span class="my-avatar-name" :class="{ 'ortho-text-gray': boilerplate }">{{ name[0] }}</span>
			</v-avatar>
		</v-badge>
	</div>
</template>

<script>
export default {
	name: 'MyAvatar',
	props: { name: String, boilerplate: Boolean, position: Number },
};
</script>

<style lang="scss" scoped>
.my-avatar-container {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 10px;
		height: 18px;
		background-color: #f7f7f7;
		top: 0;
		right: 0px;
	}

	.my-avatar-name {
		font-family: 'Lato';
		font-size: 25px;
		font-weight: 600;
	}
}

.my-avatar-container.boilerplate {
	.my-avatar {
		border: 2px dashed #989fa9;
	}
	&::after {
		background-color: #f7f7f7;
	}
}

.my-avatar-container.rank-not-in-toplist {
	.my-avatar {
		border: 2px dashed #ea2d2d;
	}
	&::after {
		background-color: #ffb2b2;
	}
	.my-avatar-name {
		color: white;
	}
}

.my-avatar-container.rank-in-toplist {
	.my-avatar {
		border: 2px dashed #ea2d2d;
	}
	&::after {
		background-color: white;
	}
	.my-avatar-name {
		color: black;
	}
}
</style>
