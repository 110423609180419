export default new Map(
	Object.entries({
		a: 'rgb(105,239,123)',
		b: 'rgb(181,65,100)',
		c: 'rgb(194,220,184)',
		d: 'rgb(28,88,114)',
		e: 'rgb(214,190,226)',
		f: 'rgb(48,64,141)',
		g: 'rgb(192,225,92)',
		h: 'rgb(200,107,225)',
		i: 'rgb(115,140,78)',
		j: 'rgb(89,32,175)',
		k: 'rgb(46,236,230)',
		l: 'rgb(169,55,5)',
		m: 'rgb(244,187,143)',
		n: 'rgb(71,154,188)',
		o: 'rgb(27,81,29)',
		p: 'rgb(238,28,194)',
		q: 'rgb(34,151,67)',
		r: 'rgb(112,70,83)',
		s: 'rgb(255,28,93)',
		t: 'rgb(242,192,41)',
		u: 'rgb(84,126,236)',
		v: 'rgb(254,116,70)',
		w: 'rgb(159,4,252)',
		x: 'rgb(171,123,5)',
		y: 'rgb(246,138,173)',
		z: 'rgb(198,82,62)',
	})
);
